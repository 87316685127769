import { createContext, Dispatch, SetStateAction } from "react";
import { initialFormValues, FormSchemaType } from "../schema/single-donation";

const initialValue: {
  donationData: FormSchemaType;
  setDonationData: Dispatch<SetStateAction<FormSchemaType>>;
} = {
  donationData: initialFormValues,
  setDonationData() {},
};

const DonationContext = createContext(initialValue);

export default DonationContext;
