import * as yup from "yup";

export const minMax = yup.addMethod(
  yup.string,
  "minMax",
  function (message: string, min: number, max: number) {
    return this.test("minMax", message, (value): boolean => {
      if (value) {
        return value.length >= min && value.length <= max;
      }
      return true;
    });
  }
);

export const negativeMatch = yup.addMethod(
  yup.string,
  "negativeMatch",
  function (message: string, regex: RegExp) {
    return this.test("negativeMatch", message, (value): boolean => {
      if (value) {
        return !regex.test(value);
      }
      return false;
    });
  }
);

export const notOnlyNum = yup.addMethod(
  yup.string,
  "notOnlyNum",
  function (message: string) {
    return this.test(
      "notOnlyNum",
      message,
      (value): boolean => !value || Number.isNaN(Number(value))
    );
  }
);

export const addressFieldEdgeCase = yup.addMethod(
  yup.string,
  "addressFieldEdgeCase",
  function (message: string, regex: RegExp, field: string, field2: string) {
    // address line 1 matches address line 2
    return this.test("addressFieldEdgeCase", message, (value: any): boolean => {
      const addressField = document.getElementsByName(
        field
      )[0] as HTMLInputElement;
      const addressField2 = document.getElementsByName(
        field2
      )[0] as HTMLInputElement;
      if (
        regex.test(value) &&
        addressField?.value === "" &&
        addressField2?.value === ""
      ) {
        return false;
      }
      return true;
    });
  }
);

export const equalsOtherField = yup.addMethod(
  yup.string,
  "equalsOtherField",
  function (message: string, field: string) {
    return this.test("equalsOtherField", message, (value: any): boolean => {
      const comparisonField = document.getElementsByName(
        field
      )[0] as HTMLInputElement;
      if (comparisonField && value) {
        return (
          comparisonField.value.trim().toLowerCase() !==
          value.trim().toLowerCase()
        );
      }
      return true;
    });
  }
);

export const firstDigitCheck = yup.addMethod(
  yup.string,
  "firstDigitCheck",
  function (message: string) {
    return this.test("firstDigitCheck", message, (value: any): boolean => {
      if (value) {
        const firstTwoNumbers = value.substring(0, 2);
        return message.includes("mobile")
          ? firstTwoNumbers === "07"
          : firstTwoNumbers === "01" ||
              firstTwoNumbers === "02" ||
              firstTwoNumbers === "07";
      }
      return true;
    });
  }
);

export const ukOnlyMobileNumber = yup.addMethod(
  yup.string,
  "ukOnlyMobileNumber",
  function (message: string) {
    const disallowedPrefixes = [
      "07797",
      "07624",
      "07524",
      "07924",
      "07781",
      "07839",
      "07911",
      "07509",
      "07937",
      "07700",
      "07829",
    ];
    return this.test("ukOnlyMobileNumber", message, (value: any): boolean => {
      if (value) {
        const trimmedVal = value.trim().replace(/\s/g, "");
        if (trimmedVal.substring(0, 2) === "07") {
          const firstFiveNumbers = trimmedVal.substring(0, 5);
          if (disallowedPrefixes.includes(firstFiveNumbers)) {
            return false;
          }
          return true;
        }
        return false;
      }
      return true;
    });
  }
);
