import React, { ReactElement } from "react";
import { Step } from "@cruk/cruk-react-components";
import styled from "styled-components";

type ProgressBarProps = {
  steps: string[];
  current: number;
};

const MaxWidthContainer = styled.div`
  text-align: center;
  text-transform: capitalize;
  max-width: ${({ theme }) => theme.utilities.contentMaxWidth};
  margin: ${({ theme }) => theme.spacing.m} auto 0;
  padding: 0 ${({ theme }) => theme.spacing.s};
`;

const StepContainer = styled.div`
  margin: 0 auto;

  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    max-width: 70%;
  }
`;

const ProgressBar = ({ steps, current }: ProgressBarProps): ReactElement => (
  <MaxWidthContainer>
    <StepContainer>
      <Step steps={steps} current={current} />
    </StepContainer>
  </MaxWidthContainer>
);

export default ProgressBar;
