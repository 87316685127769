import * as yup from "yup";
import { isUkCountry } from "../helpers/functions/shared";
import {
  addressLine1RegExp,
  addressLine23RegExp,
  amountRegExp,
  andRegExp,
  cityRegExp,
  extraEmailRegExp,
  nameRegExp,
  nameExcludedRegExp,
  postcodeIntlRegExp,
  postcodeUkRegExp,
  singleCharRegExp,
  phoneRegExp,
  numbersOnlyRegExp,
  sortCodeRegExp,
} from "../helpers/regexp";
import { Option } from "../helpers/types/shared";
import "../helpers/yupMethods";

const getRequiredMessage = (hasNudges: boolean) =>
  `Please ${
    hasNudges ? "select or " : ""
  }type in the amount you'd like to donate.`;

const donationAmountSchema = (
  frequency: Option<string>,
  formVersion: string
) => {
  let max: number;

  switch (frequency) {
    case "Monthly":
      max = 850;
      break;
    case "Quarterly":
      max = 2500;
      break;
    case "Annually":
      max = 10000;
      break;
    default:
      max = 100000;
  }

  const base = {
    amountRadioGroup: yup.mixed().when("otherAmount", {
      is: "" || undefined,
      then: yup
        .string()
        .when("$hasNudges", (hasNudges: boolean, schema) =>
          schema.required(getRequiredMessage(hasNudges))
        ),
      otherwise: yup.string(),
    }),
    otherAmount: yup.mixed().when("amountRadioGroup", {
      is: "" || undefined,
      then: yup
        .string()
        .matches(
          amountRegExp,
          "Please enter an amount using numbers and a decimal point only."
        )
        .test(
          "min",
          "Please enter an amount of £2 or greater.",
          (userInput) => !userInput || Number(userInput) >= 2
        )
        .test(
          "max",
          formVersion === "regular"
            ? "Thank you for your generous donation. So that we’re able to thank you appropriately, please call our Supporter Services team on 0300 123 6646 to complete the transaction."
            : "We only accept donations up to £100,000 online. To donate a different amount call us on 0300 123 1022.",
          (userInput) =>
            (typeof frequency === "undefined" && formVersion === "regular") ||
            !userInput ||
            Number(userInput) <= max
        )
        .when("$hasNudges", (hasNudges: boolean, schema) =>
          schema.required(getRequiredMessage(hasNudges))
        ),
      otherwise: yup.string(),
    }),
  };

  return {
    fields: base,
    noSortEdges: ["amountRadioGroup", "otherAmount"],
  };
};

export const DonationAmountFormSchema = yup.object().shape({
  donationAmount: yup
    .object()
    // @ts-ignore
    .when(["frequency", "$formVersion"], (frequency, formVersion, schema) => {
      const { fields, noSortEdges } = donationAmountSchema(
        frequency,
        formVersion
      );
      return schema.shape(fields, noSortEdges);
    }),
  frequency: yup.string().when("$formVersion", {
    is: "regular",
    then: yup
      .string()
      .required("Please select how often you'd like to make your donation."),
    otherwise: yup.string(),
  }),
  typeRadioGroup: yup.string().when("$formVersion", {
    is: "single",
    then: yup.string().required("Please select an option to continue."),
    otherwise: yup.string(),
  }),
  motivation: yup.string(),
  otherDonationMotivation: yup
    .string()
    .trim()
    .max(100, "Please complete with a maximum of 100 characters."),
  inMemoryName: yup
    .string()
    .trim()
    .matches(nameRegExp, {
      message: "Please only use letters and hyphens.",
    })
    .max(100, "Please complete with a maximum of 100 characters."),
  destinationRadioGroup: yup
    .string()
    .required("Please select an option to continue"),
  restriction: yup.string(),
  emailOptIn: yup.string(),
  postOptIn: yup.string(),
  textOptIn: yup.string(),
  phoneOptIn: yup.string(),
});

export const DetailsFormSchema = yup.object().shape({
  title: yup.string(),
  forename: yup
    .string()
    .trim()
    .required("Please enter your first name.")
    .max(
      50,
      "Please enter your first name, over 50 characters is not accepted."
    )
    .matches(nameRegExp, {
      message: "Please only use letters and hyphens.",
    })
    // @ts-expect-error
    .negativeMatch(
      "Please enter your first name, this cannot contain the word 'and'.",
      andRegExp
    )
    .negativeMatch("Please enter a valid first name.", nameExcludedRegExp),
  surname: yup
    .string()
    .trim()
    .required("Please enter your last name.")
    .matches(nameRegExp, {
      message: "Please only use letters and hyphens.",
    })
    // @ts-expect-error
    .minMax("Please enter your last name between 2 - 50 characters.", 2, 50)
    .negativeMatch(
      "Please enter your last name, this cannot contain the word 'and'.",
      andRegExp
    )
    .negativeMatch("Please enter a valid last name.", nameExcludedRegExp),
  emailAddress: yup
    .string()
    .trim()
    .email("Please enter a valid email address.")
    .required("Please enter a valid email address.")
    // @ts-expect-error
    .negativeMatch("Please enter a valid email address.", extraEmailRegExp)
    .max(
      100,
      "Please enter your email address. Over 100 characters is not accepted."
    ),
  phoneNumber: yup
    .string()
    .trim()
    .matches(phoneRegExp, {
      message: "Please enter a valid phone number so we can contact you.",
      excludeEmptyString: true,
    })
    .when(["textOptIn", "phoneOptIn"], {
      is: (textOptIn: string, phoneOptIn: string) =>
        phoneOptIn === "yes" || textOptIn === "yes",
      then: (schema) =>
        schema.required(
          "Please enter a valid phone number so we can contact you."
        ),
      otherwise: (schema) => schema,
    }),
  addressLine1: yup
    .string()
    .trim()
    .required("Please complete address line 1.")
    .matches(addressLine1RegExp, {
      message: "Please only use letters, numbers and the following: - ' / . ,",
    })
    .test(
      "mimax",
      "Please enter address line 1 between 2 - 200 characters.",
      (userInput) =>
        !userInput ||
        (!(userInput.replace(/[, ]/g, "").length < 2) &&
          !(userInput.replace(/[, ]/g, "").length > 200))
    )
    // @ts-expect-error
    .notOnlyNum(
      "Please complete address line 1 including your house number and street name."
    )
    .equalsOtherField("Town name should not appear in address line 1.", "city")
    .addressFieldEdgeCase(
      "Please enter address line 1 between 2 - 200 characters.",
      singleCharRegExp,
      "addressLine2",
      "addressLine3"
    ),
  addressSelection: yup.string().when("addressSearchStatus", {
    is: "search",
    then: yup.string().required("Please select an address."),
  }),
  addressSearchStatus: yup.string(),
  addressLine2: yup
    .string()
    .trim()
    .matches(addressLine23RegExp, {
      message: "Please only use letters, numbers and the following: - ' / . ,",
      excludeEmptyString: true,
    })
    .max(
      100,
      "Please enter address line 2. Over 100 characters is not accepted."
    )
    // @ts-expect-error
    .equalsOtherField("Town name should not appear in address line 2.", "city"),
  addressLine3: yup
    .string()
    .trim()
    .matches(addressLine23RegExp, {
      message: "Please only use letters, numbers and the following: - ' / . ,",
      excludeEmptyString: true,
    })
    .max(
      100,
      "Please enter address line 3. Over 100 characters is not accepted."
    )
    // @ts-expect-error
    .equalsOtherField("Town name should not appear in address line 3.", "city"),
  city: yup
    .string()
    .trim()
    .required("Please enter your town or city.")
    .matches(cityRegExp, {
      message: "Please only use letters and the following: - ' / .",
    })
    .max(
      50,
      "Please enter your town or city. Over 50 characters is not accepted."
    ),
  postalCode: yup
    .string()
    .trim()
    .required("Please enter a valid postcode.")
    // @ts-expect-error
    .minMax("Please enter a postcode between 2 - 8 characters.", 2, 8)
    .when("country", {
      is: (country: string) => isUkCountry(country),
      then: yup.string().trim().matches(postcodeUkRegExp, {
        message: "Please enter a valid postcode.",
      }),
      otherwise: yup.string().trim().matches(postcodeIntlRegExp, {
        message: "Please enter a valid postcode.",
      }),
    }),
  country: yup.string().required(),
  validationStatus: yup.string().required(),
});

export const PaymentFormSchema = yup.object().shape({
  paymentRadioGroup: yup.string().when("$formVersion", {
    is: "single",
    then: yup.string().required("Please select a payment method to continue."),
    otherwise: yup.string(),
  }),
  cardholderName: yup.string().when(["$formVersion", "paymentRadioGroup"], {
    is: (formVersion: string, paymentRadioGroup: string) =>
      formVersion === "single" && paymentRadioGroup === "bt",
    then: yup
      .string()
      .trim()
      .required(
        "Please enter the name of the cardholder as it appears on the card."
      )
      // @ts-expect-error
      .minMax(
        "Please enter your cardholder name between 2 - 175 characters.",
        2,
        175
      )
      .matches(nameRegExp, {
        message: "Please only use letters and hyphens.",
      })
      .negativeMatch(
        "Please enter your cardholder name, this cannot contain the word 'and'.",
        andRegExp
      )
      .negativeMatch(
        "Please enter a valid cardholder name.",
        nameExcludedRegExp
      ),
    otherwise: yup.string(),
  }),
  giftAid: yup.boolean(),
  billingAddress: yup.array().nullable(),
  billingAddressLine1: yup.string().when("billingAddress", {
    is: (val: string) => val && val.includes("yes"),
    then: yup
      .string()
      .trim()
      .required("Please complete address line 1.")
      .test(
        "mimax",
        "Please enter address line 1 between 2 - 200 characters.",
        (userInput) =>
          !userInput ||
          (!(userInput.replace(/[, ]/g, "").length < 2) &&
            !(userInput.replace(/[, ]/g, "").length > 200))
      )
      .matches(addressLine1RegExp, {
        message:
          "Please only use letters, numbers and the following: - ' / . ,",
        excludeEmptyString: true,
      })
      // @ts-expect-error
      .notOnlyNum(
        "Please complete address line 1 including your house number and street name."
      )
      .equalsOtherField(
        "Town name should not appear in address line 1.",
        "billingCity"
      )
      .addressFieldEdgeCase(
        "Please enter address line 1 between 2 - 200 characters.",
        singleCharRegExp,
        "billingAddressLine2",
        "billingAddressLine3"
      ),
    otherwise: yup.string(),
  }),
  billingAddressSelection: yup.string().when("billingAddressSearchStatus", {
    is: "search",
    then: yup.string().required("Please select an address."),
  }),
  billingAddressSearchStatus: yup.string(),
  billingAddressLine2: yup
    .string()
    .trim()
    .matches(addressLine23RegExp, {
      message: "Please only use letters, numbers and the following: - ' / . ,",
      excludeEmptyString: true,
    })
    .max(
      100,
      "Please enter address line 2. Over 100 characters is not accepted."
    )
    // @ts-expect-error
    .equalsOtherField(
      "Town name should not appear in address line 2.",
      "billingCity"
    ),
  billingAddressLine3: yup
    .string()
    .trim()
    .matches(addressLine23RegExp, {
      message: "Please only use letters, numbers and the following: - ' / . ,",
      excludeEmptyString: true,
    })
    .max(
      100,
      "Please enter address line 3. Over 100 characters is not accepted."
    )
    // @ts-expect-error
    .equalsOtherField(
      "Town name should not appear in address line 3.",
      "billingCity"
    ),
  billingCity: yup.string().when("billingAddress", {
    is: (val: string) => val && val.includes("yes"),
    then: yup
      .string()
      .trim()
      .required("Please enter your town or city.")
      .max(
        50,
        "Please enter your town or city. Over 50 characters is not accepted."
      )
      .matches(cityRegExp, {
        message: "Please only use letters and the following: - ' / .",
      }),
    otherwise: yup.string(),
  }),
  billingPostalCode: yup
    .string()
    .trim()
    // @ts-expect-error
    .minMax("Please enter a postcode between 2 - 8 characters.", 2, 8)
    .when("billingAddress", {
      is: (val: string) => val && val.includes("yes"),
      then: yup
        .string()
        .required("Please enter a valid postcode.")
        .when("billingCountry", {
          is: (val: string) => isUkCountry(val),
          then: yup.string().trim().matches(postcodeUkRegExp, {
            message: "Please enter a valid postcode.",
          }),
          otherwise: yup.string().trim().matches(postcodeIntlRegExp, {
            message: "Please enter a valid postcode.",
          }),
        }),
    }),
  billingCountry: yup.string(),
});

export const RegularPaymentFormSchema = yup.object().shape({
  accountName: yup.string().when("$formVersion", {
    is: "regular",
    then: yup
      .string()
      .required("Please enter the account holder's name.")
      // @ts-expect-error
      .minMax(
        "Please enter the account holder's name between 2 - 50 characters.",
        2,
        50
      )
      .matches(nameRegExp, {
        message: "Please only use letters and hyphens.",
      }),
    otherwise: yup.string(),
  }),
  accountNumber: yup.string().when("$formVersion", {
    is: "regular",
    then: yup
      .string()
      .required("Please enter your account number.")
      .matches(numbersOnlyRegExp, {
        message: "Please enter a valid account number.",
      })
      .length(8, "Please enter account number with 8 digits."),
    otherwise: yup.string(),
  }),
  sortCode: yup.string().when("$formVersion", {
    is: "regular",
    then: yup
      .string()
      .required("Please enter your sort code.")
      .matches(sortCodeRegExp, {
        message: "Please enter a valid sort code.",
      })
      .test(
        "minmax",
        "Your sort code should have 6 digits.",
        (userInput) => !userInput || userInput.replace(/[- ]/g, "").length === 6
      ),
    otherwise: yup.string(),
  }),
  donationDate: yup.string().when("$formVersion", {
    is: "regular",
    then: yup
      .string()
      .required("Please select the date you'd like to make your donation on."),
    otherwise: yup.string(),
  }),
});

export const DonationAndDetailFormSchema =
  DonationAmountFormSchema.concat(DetailsFormSchema);

export const FormSchema = PaymentFormSchema.concat(DonationAndDetailFormSchema);

export const RegularFormSchema = RegularPaymentFormSchema.concat(
  DonationAndDetailFormSchema
);

export const initialDonationAmountFormValues = {
  donationAmount: {
    amountRadioGroup: "",
    otherAmount: "",
  },
  typeRadioGroup: "",
  frequency: "",
  motivation: "",
  otherDonationMotivation: "",
  inMemoryName: "",
  destinationRadioGroup: "greatest",
  restriction: "",
  emailOptIn: "",
  postOptIn: "yes",
  textOptIn: "",
  phoneOptIn: "yes",
};

export const initialDetailsFormValues = {
  title: "",
  forename: "",
  surname: "",
  emailAddress: "",
  phoneNumber: "",
  addressLine1: "",
  addressSelection: "",
  addressSearchStatus: "",
  addressLine2: "",
  addressLine3: "",
  city: "",
  postalCode: "",
  country: "United Kingdom",
  validationStatus: "NV",
};

export const initialPaymentFormValues = {
  cardholderName: "",
  paymentRadioGroup: "",
  giftAid: false,
  billingAddress: "",
  billingAddressLine1: "",
  billingAddressSelection: "",
  billingAddressSearchStatus: "",
  billingAddressLine2: "",
  billingAddressLine3: "",
  billingCity: "",
  billingPostalCode: "",
  billingCountry: "United Kingdom",
};

export const initialRegularPaymentFormValues = {
  accountName: "",
  accountNumber: "",
  sortCode: "",
  donationDate: "",
};

export const initialFormValues = {
  ...initialDonationAmountFormValues,
  ...initialDetailsFormValues,
  ...initialPaymentFormValues,
  ...initialRegularPaymentFormValues,
};

export type FormSchemaType = typeof initialFormValues;
